import _ from 'lodash';
import { API_URI } from 'src/constants';
import { timestamp } from './helper';

// Service : GET ------------------------- */
export async function GET (url = '', external = false, all = false) {
  const _url = external ? `${url}` : `${API_URI}${url}`;
  try {
    const response = await fetch(_url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain' // Solved issues call OPTIONS
      }
    });

    if (!response.ok) {
      throw new TypeError('Network response was not OK');
    }
    // console.log('GET', response, typeof response);
    const data = await response.json();
    // If don't have data return [];
    // If have data return [{ id: 1 }] or { data: [] }
    // If can't call api return { data: null, msg: null }
    // console.log(timestamp(), _url, ':');
    if (!data.msg) {
      if (!_.isEmpty(data.data)) {
        return all ? { ...data } : data.data;
      }
      return data;
    }
    return [];
  } catch (err) {
    console.log(`${timestamp()} ===========> GET ERROR : ${_url}`);
    console.log(`${err}`);
  }
};

export async function GET_ARTICLES (url = '', external = false, all = false) {
  const _url = external ? `${url}` : `${API_URI}${url}`;

  try {
    const response = await fetch(_url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain' // Solved issues call OPTIONS
      }
    });

    if (!response.ok) {
      throw new TypeError('Network response was not OK');
    }
    const data = await response.json();
    if (data?.statusCode === 404) {
      return data;
    } else if (!_.isEmpty(data?.data)) {
      return all ? { ...data } : data?.data;
    }
    return all ? { ...data } : [];
  } catch (err) {
    console.log(`${new Date().toUTCString()} ===========> GET ERROR : ${_url}`);
    console.log(`${err}`);
  }
};
